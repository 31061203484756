import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h4>{`Etching and cleaning tools`}</h4>
    <p>{`Etching tools create permanent patterns in chips: after photolithography removes portions of a photoresist deposited on a wafer in a precise pattern, etching tools etch that pattern into a permanent substrate below. Cleaning tools then remove etched materials.`}</p>
    <p>{`The United States and Japan are the main producers of etch and clean equipment; South Korea and China are also significant suppliers.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      